import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const ClubListPage = () => {
    const [clubs, setClubs] = useState([]);
    const [sports, setSports] = useState([]); // State to store list of sports
    const [newClub, setNewClub] = useState({
        sportId: '',
        name: '',
        avatar: '',
        foundedDate: '',
        operatingHours: '',
        location: '',
        president: '',
        introduction: '',
        notes: ''
    });
    const [editingClub, setEditingClub] = useState(null);
    const [showModal, setShowModal] = useState(false);  // Modal state
    const [deleteId, setDeleteId] = useState(null);  // ID để xác nhận xóa

    // Fetch clubs and sports when the component loads
    useEffect(() => {
        fetchClubs();
        fetchSports(); // Fetch the sports list for the dropdown
    }, []);

    const fetchClubs = async () => {
        const response = await axios.get('/clubs');
        setClubs(response.data);
    };

    const fetchSports = async () => {
        const response = await axios.get('/sports');
        setSports(response.data); // Set sports state with the fetched data
    };

    // Thêm hoặc cập nhật câu lạc bộ
    const handleSaveClub = async () => {
        if (editingClub) {
            await axios.put(`/clubs/${editingClub._id}`, editingClub);
        } else {
            await axios.post('/clubs', newClub);
            setNewClub({
                sportId: '',
                name: '',
                avatar: '',
                foundedDate: '',
                operatingHours: '',
                location: '',
                president: '',
                introduction: '',
                notes: ''
            });
        }
        setShowModal(false);
        setEditingClub(null);
        fetchClubs();
    };

    // Xóa câu lạc bộ
    const handleDeleteClub = async (id) => {
        await axios.delete(`/clubs/${id}`);
        setDeleteId(null);
        fetchClubs();
    };

    // Mở Modal cho thêm hoặc sửa
    const openModal = (club = null) => {
        setEditingClub({
            ...club,
            sportId: club.sportId._id || club.sportId  // Lấy đúng sportId nếu nó là object hoặc string
        });
        setNewClub(club || {
            sportId: '',
            name: '',
            avatar: '',
            foundedDate: '',
            operatingHours: '',
            location: '',
            president: '',
            introduction: '',
            notes: ''
        });
        setShowModal(true);
    };

    // Đóng Modal
    const closeModal = () => {
        setEditingClub(null);
        setShowModal(false);
    };

    // Upload ảnh
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);  // 'avatar' là tên của trường file trong backend

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingClub) {
                setEditingClub({ ...editingClub, avatar: fileUrl });
            } else {
                setNewClub({ ...newClub, avatar: fileUrl });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Xác nhận xóa
    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    return (
        <Container>
            <h2 className="my-4">Club Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Club</Button>

            <h3>Existing Clubs</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th>President</th>
                        <th>Avatar</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {clubs.map((club) => (
                        <tr key={club._id}>
                            <td>{club.name}</td>
                            <td>{club.location}</td>
                            <td>{club.president}</td>
                            <td>
                                <img src={`${process.env.REACT_APP_API_BASE_URL}/${club.avatar}`} alt="Avatar" width="100" />
                            </td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(club)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => confirmDelete(club._id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal cho thêm/sửa câu lạc bộ */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingClub ? 'Edit Club' : 'Create New Club'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Dropdown to select a sport */}
                        <Form.Group className="mb-3">
                            <Form.Label>Select Sport</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingClub ? editingClub.sportId : newClub.sportId}
                                onChange={(e) => {
                                    const sportId = e.target.value;
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, sportId });
                                    } else {
                                        setNewClub({ ...newClub, sportId });
                                    }
                                }}
                            >
                                <option value="">Select a Sport</option>
                                {sports.map((sport) => (
                                    <option key={sport._id} value={sport._id}>
                                        {sport.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Club Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingClub ? editingClub.name : newClub.name}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, name: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, name: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingClub ? editingClub.location : newClub.location}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, location: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, location: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>President</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingClub ? editingClub.president : newClub.president}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, president: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, president: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Founded Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={editingClub ? editingClub.foundedDate : newClub.foundedDate}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, foundedDate: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, foundedDate: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Operating Hours</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingClub ? editingClub.operatingHours : newClub.operatingHours}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, operatingHours: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, operatingHours: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Introduction</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingClub ? editingClub.introduction : newClub.introduction}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, introduction: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, introduction: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingClub ? editingClub.notes : newClub.notes}
                                onChange={(e) => {
                                    if (editingClub) {
                                        setEditingClub({ ...editingClub, notes: e.target.value });
                                    } else {
                                        setNewClub({ ...newClub, notes: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveClub}>
                        {editingClub ? 'Save Changes' : 'Create Club'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this club?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteClub(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ClubListPage;
