import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';  // Đảm bảo import Navigate
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import ClubList from './components/ClubList';
import ProgramList from './components/ProgramList';
import Contact from './components/Contact';
import CoachList from './components/CoachList';
import RefereeList from './components/RefereeList';
import StadiumList from './components/StadiumList';
import VideoList from './components/VideoList';
import ClubRegistrationForm from './components/ClubRegistrationForm';
import LoginPage from './components/Auth/LoginPage';
//import ProtectedRoutes from './routes/ProtectedRoutes';  // Import ProtectedRoutes
import ProtectedAdminRoutes from './routes/ProtecedAdminRoutes';
import ProtectedManageRoutes from './routes/ProtectedManageRoutes';  // Import ProtectedRoutes
import { AuthProvider } from './context/AuthContext';  // Import AuthProvider
import './App.css';
import VideoDetail from './components/VideoDetail';
import axios from './api/api';

function PublicRoutes() {
    return (
        <div>
            <Header />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/clubs" element={<ClubList />} />
                <Route path="/coaches" element={<CoachList />} />
                <Route path="/referees" element={<RefereeList />} />
                <Route path="/stadiums" element={<StadiumList />} />
                <Route path="/programs" element={<ProgramList />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/videos" element={<VideoList />} />
                <Route path="/club-register" element={<ClubRegistrationForm />} />
                <Route path="/video-detail/:videoId" element={<VideoDetail />} />

                <Route path="*" element={<Navigate to="/" />} />  {/* Điều hướng trang không tìm thấy */}
                
            </Routes>
            <Footer />
        </div>
    );
}

function App() {
    useEffect(() => {
        const sessionKey = 'user_session';
        const session = localStorage.getItem(sessionKey);
        const visitedUrl = window.location.pathname;

        if (!session) {
            // Tạo phiên mới và lưu trữ trong localStorage
            const newSession = `session_${new Date().getTime()}`;
            localStorage.setItem(sessionKey, newSession);

            // Gọi API để tăng đếm phiên truy cập
            axios.post('/logs/track-visit', { sessionId: newSession, visitedUrl })
                .then((response) => {
                    console.log('Tracked visit:', response.data);
                })
                .catch((error) => {
                    console.error('Error tracking visit:', error);
                });
        }
    }, []);


    return (
        <AuthProvider>
            <Router>
                <Routes>                    
                <Route path="/manage/*" element={<ProtectedManageRoutes />} /> 
                <Route path="/admin/*" element={<ProtectedAdminRoutes />} />                                    
                <Route path="/*" element={<PublicRoutes />} />
                                       
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
