import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const PlaygroundManagementListPage = () => {
    const [stadiums, setStadiums] = useState([]);
    const [selectedStadium, setSelectedStadium] = useState(null);
    const [playgrounds, setPlaygrounds] = useState([]);
    const [newPlayground, setNewPlayground] = useState({
        name: '',
        description: '',
        orientation: '',
        status: 'Sẵn sàng cho thuê'
    });
    const [editingPlayground, setEditingPlayground] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchUserStadiums();
    }, []);

    const fetchUserStadiums = async () => {
        try {
            const response = await axios.get('/stadiums/user-stadiums');
            setStadiums(response.data);

            if (response.data.length) {
                setSelectedStadium(response.data[0]._id);
                fetchPlaygrounds(response.data[0]._id);
            }
        } catch (error) {
            console.error('Error fetching stadiums:', error);
        }
    };

    const fetchPlaygrounds = async (stadiumId) => {
        try {
            const response = await axios.get(`/playgrounds/get-playgrounds/${stadiumId}`);
            setPlaygrounds(response.data);
        } catch (error) {
            console.error('Error fetching playgrounds:', error);
        }
    };

    const handleSavePlayground = async () => {
        try {
            if (editingPlayground && editingPlayground._id) {
                await axios.put(`/playgrounds/${editingPlayground._id}`, editingPlayground);
            } else {
                await axios.post('/playgrounds', { ...newPlayground, stadiumManagementId: selectedStadium });
            }
            setShowModal(false);
            fetchPlaygrounds(selectedStadium);
        } catch (error) {
            console.error('Error saving playground:', error);
        }
    };

    const handleDeletePlayground = async (id) => {
        try {
            await axios.delete(`/playgrounds/${id}`);
            fetchPlaygrounds(selectedStadium);
        } catch (error) {
            console.error('Error deleting playground:', error);
        }
    };

    const openModal = (playground = null) => {
        setEditingPlayground(playground || null);
        setNewPlayground({
            name: '',
            description: '',
            orientation: '',
            status: 'Sẵn sàng cho thuê'
        });
        setShowModal(true);
    };

    return (
        <Container>
            <h2 className="my-4">Quản lý Sân Chơi</h2>

            {stadiums.length ? (
                <Form.Group controlId="stadiumSelect">
                    <Form.Label>Chọn Ban Quản Lý Sân</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedStadium}
                        onChange={(e) => {
                            setSelectedStadium(e.target.value);
                            fetchPlaygrounds(e.target.value);
                        }}
                    >
                        {stadiums.map((stadium) => (
                            <option key={stadium._id} value={stadium._id}>
                                {stadium.stadiumName}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            ) : (
                <p>Bạn chưa được phân công quản lý sân nào</p>
            )}

            <Button variant="primary" className="my-4" onClick={() => openModal()}>Thêm mới sân chơi</Button>

            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Tên sân</th>
                        <th>Mô tả</th>
                        <th>Hướng</th>
                        <th>Trạng thái</th>
                        <th>Hành động</th>
                    </tr>
                </thead>
                <tbody>
                    {playgrounds.map((playground) => (
                        <tr key={playground._id}>
                            <td>{playground.name}</td>
                            <td>{playground.description}</td>
                            <td>{playground.orientation}</td>
                            <td>{playground.status}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(playground)}
                                >
                                    Sửa
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleDeletePlayground(playground._id)}
                                >
                                    Xóa
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingPlayground ? 'Sửa sân chơi' : 'Thêm sân chơi mới'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="name">
                            <Form.Label>Tên sân</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingPlayground ? editingPlayground.name : newPlayground.name}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, name: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, name: value }));
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="description">
                            <Form.Label>Mô tả</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingPlayground ? editingPlayground.description : newPlayground.description}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, description: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, description: value }));
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="orientation">
                            <Form.Label>Hướng</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingPlayground ? editingPlayground.orientation : newPlayground.orientation}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, orientation: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, orientation: value }));
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="status">
                            <Form.Label>Trạng thái</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingPlayground ? editingPlayground.status : newPlayground.status}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingPlayground) {
                                        setEditingPlayground((prev) => ({ ...prev, status: value }));
                                    } else {
                                        setNewPlayground((prev) => ({ ...prev, status: value }));
                                    }
                                }}
                            >
                                <option value="Sẵn sàng cho thuê">Sẵn sàng cho thuê</option>
                                <option value="Không sẵn sàng cho thuê">Không sẵn sàng cho thuê</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Đóng</Button>
                    <Button variant="primary" onClick={handleSavePlayground}>
                        {editingPlayground ? 'Cập nhật' : 'Thêm mới'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default PlaygroundManagementListPage;
