
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import AdminHeader from '../components/admin/AdminHeader';
import CommonMenu from '../components/community/CommonMenu';  // Menu chung cho mọi người
import CommunityLayout from '../components/community/CommunityLayout';  // Layout cho user thông thường

const ProtectedManageRoutes = () => {
    const { auth, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    return auth ? (
        
        <Routes>            
            <Route path='*' element = {
                <div>
                    <AdminHeader />
                    <div style={{ display: 'flex' }}>
                        <CommonMenu />
                        <CommunityLayout />
                    </div>
                </div>
            }
            />            
            
        </Routes>
    ) : (
        <Navigate to="/login" />
    );
};

export default ProtectedManageRoutes;
