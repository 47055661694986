import React, { useEffect, useState } from 'react';
import axios from './../api/api';
import Slider from 'react-slick'; // Thêm import Slider từ react-slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CoachesHomepage = ({ coachConfigValue }) => {
    const [coaches, setCoaches] = useState([]);

    useEffect(() => {
        console.log('Received coachConfigValue:', coachConfigValue);

        const fetchCoaches = async () => {
            try {
                const coachIdsArray = coachConfigValue.slice(1, -1).split(',');
                const sanitizedIds = coachIdsArray.map(id => id.trim());

                const response = await axios.get('/coaches/coaches-by-ids', {
                    params: { ids: sanitizedIds }
                });

                setCoaches(response.data);
            } catch (error) {
                console.error('Error fetching coaches:', error);
            }
        };

        if (coachConfigValue) {
            fetchCoaches();
        }
    }, [coachConfigValue]);

    // Cấu hình cho slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Hiển thị 2 huấn luyện viên trên mỗi dòng
        slidesToScroll: 1, // Trượt một lần 1 nhóm
        autoplay: true,
        autoplaySpeed: 6000, // Tự động chuyển sau 6 giây
    };

    return (
        <div>
            <div style={{
                backgroundColor: '#007BFF', // Màu nền xanh
                color: 'white',              // Chữ trắng
                textAlign: 'center',         // Canh giữa
                padding: '10px',             // Khoảng cách trên dưới
                fontSize: '1.5em',           // Kích thước chữ nhỏ hơn
            }}>
                Huấn luyện viên tiêu biểu
            </div>
            {coaches.length > 0 && (
                <Slider {...settings}>
                    {coaches.map((coach, index) => (
                        <div key={index} style={{ padding: '10px', textAlign: 'center' }}>
                            <img 
                                src={`${process.env.REACT_APP_API_BASE_URL}/${coach.avatar}`}
                                alt={coach.fullName} 
                                style={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'cover' }} 
                            />
                            <h3>{coach.fullName}</h3>
                            <p><strong>Khu vực giảng dạy:</strong> {coach.teachingArea}</p>
                            <p>{coach.bio}</p>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default CoachesHomepage;
