import React, { useEffect, useState } from 'react';
import axios from './../api/api';
import Slider from 'react-slick'; // Import Slider từ react-slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StadiumHomepage = ({ stadiumConfigValue }) => {
    const [stadiums, setStadiums] = useState([]);

    useEffect(() => {
        console.log('Received stadiumConfigValue:', stadiumConfigValue);

        const fetchStadiums = async () => {
            try {
                // Convert string of stadium IDs into an array
                const stadiumIdsArray = stadiumConfigValue.slice(1, -1).split(',');
                const sanitizedIds = stadiumIdsArray.map(id => id.trim());

                // Fetch stadiums by their IDs
                const response = await axios.get('/stadiums/stadiums-by-ids', {
                    params: { ids: sanitizedIds }
                });

                setStadiums(response.data);
            } catch (error) {
                console.error('Error fetching stadiums:', error);
            }
        };

        if (stadiumConfigValue) {
            fetchStadiums();
        }
    }, [stadiumConfigValue]);

    // Slider settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Hiển thị 2 sân trên mỗi dòng
        slidesToScroll: 1, // Trượt một lần 1 nhóm
        autoplay: true,
        autoplaySpeed: 6000, // Tự động chuyển sau 6 giây
    };

    return (
        <div>
            <div style={{
                backgroundColor: '#007BFF', // Màu nền xanh
                color: 'white',              // Chữ trắng
                textAlign: 'center',         // Canh giữa
                padding: '10px',             // Khoảng cách trên dưới
                fontSize: '1.5em',           // Kích thước chữ nhỏ hơn
            }}>
                Sân tiêu biểu
            </div>
            {stadiums.length > 0 && (
                <Slider {...settings}>
                    {stadiums.map((stadium, index) => (
                        <div key={index} style={{ padding: '10px', textAlign: 'center' }}>
                            <img 
                                src={`${process.env.REACT_APP_API_BASE_URL}/${stadium.avatar}`}
                                alt={stadium.stadiumName} 
                                style={{ width: '100%', height: 'auto', maxHeight: '200px', objectFit: 'cover' }} 
                            />
                            <h3>{stadium.stadiumName}</h3>
                            <p><strong>Đại diện:</strong> {stadium.representativeName}</p>
                            <p><strong>Địa chỉ:</strong> {stadium.address}</p>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default StadiumHomepage;
