import React, { useState, useEffect } from 'react';
import axios from '../api/api';
import './StadiumList.css';

function StadiumList() {
    const [stadiums, setStadiums] = useState([]); // Dữ liệu các sân vận động
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Tổng số trang
    const [searchTerm, setSearchTerm] = useState(''); // Từ khóa tìm kiếm
    const stadiumsPerPage = 10;

    // Lấy danh sách sân vận động từ API với phân trang
    useEffect(() => {
        const fetchStadiums = async () => {
            try {
                const response = await axios.get('/stadiums/stadiums-paginated', {
                    params: {
                        page: currentPage,
                        limit: stadiumsPerPage,
                        search: searchTerm // Tìm kiếm theo từ khóa
                    }
                });
                setStadiums(response.data.stadiums); // Cập nhật danh sách sân vận động
                setTotalPages(response.data.totalPages); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching stadiums:', error);
            }
        };

        fetchStadiums();
    }, [currentPage, searchTerm]);

    // Xử lý tìm kiếm sân vận động
    const handleSearch = () => {
        setCurrentPage(1); // Reset về trang đầu tiên khi tìm kiếm
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="stadium-list container">
            <div className="search-bar" style={{ textAlign: 'center', marginBottom: '20px' }}>
                <input 
                    type="text" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    placeholder="Search for stadiums by name or introduction..." 
                />
                <button onClick={handleSearch}>Search</button>
            </div>

            {stadiums.length > 0 ? (
                stadiums.map(stadium => (
                    <div key={stadium._id} className="stadium-item" style={{ display: 'flex', marginBottom: '20px' }}>
                        <div className="stadium-avatar" style={{ flex: '0 0 150px', marginRight: '20px' }}>
                            <img 
                                src={`${process.env.REACT_APP_API_BASE_URL}/${stadium.avatar}`} 
                                alt={stadium.stadiumName} 
                                style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                            />
                        </div>
                        <div className="stadium-info" style={{ flex: '1' }}>
                            <h3>{stadium.stadiumName}</h3>
                            <p><strong>Address:</strong> {stadium.address}</p>
                            <p><strong>Contact:</strong> {stadium.contactPhone}</p>
                            <p><strong>Representative:</strong> {stadium.representativeName}</p>
                            <p><strong>Description:</strong> {stadium.introduction}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>No stadiums found.</p>
            )}

            <div className="pagination" style={{ textAlign: 'center', marginTop: '20px' }}>
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span> Page {currentPage} of {totalPages} </span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default StadiumList;
