import React from 'react';
import { useParams } from 'react-router-dom'; // To get the video ID from the URL

const VideoDetail = () => {
    const { videoId } = useParams(); // Get video ID from the URL

    return (
        <div className="video-detail container">
            <h2>Watch Video</h2>
            <div className="video-container" style={{ textAlign: 'center' }}>
                {/* Embed the YouTube video using the video ID */}
                <iframe
                    width="1200"
                    height="800"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default VideoDetail;
