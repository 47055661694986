import React from 'react';
import { Link } from 'react-router-dom';

const AdminMenu = () => {
    return (
        <nav style={{ width: '200px', backgroundColor: '#f8f9fa', padding: '10px' }}>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                <li>
                    <Link to="/admin/dashboard">Dashboard</Link>
                </li>
                <li>
                    <Link to="/admin/configurations">Các cấu hình</Link>
                </li>
                <li>
                    <Link to="/admin/users">User Management</Link>
                </li>
                <li>
                    <Link to="/admin/sports">Các môn thể thao</Link>
                </li>
                <li>
                    <Link to="/admin/articles">Các bài viết</Link>
                </li>
                <li>
                    <Link to="/admin/coaches">Các huấn luyện viên</Link>
                </li>
                <li>
                    <Link to="/admin/clubs">Các câu lạc bộ</Link>
                </li>
                <li>
                    <Link to="/admin/referees">Các trọng tài</Link>
                </li>
                <li>
                    <Link to="/admin/videos">Các videos</Link>
                </li>
                <li>
                    <Link to="/admin/club-registration">Quản lý đăng ký</Link>
                </li>
                <li>
                    <Link to="/admin/tournaments">Quản lý giải đấu</Link>
                </li>
                <li>
                    <Link to="/admin/stadiums">Quản lý cụm sân</Link>
                </li>
            </ul>
        </nav>
    );
};

export default AdminMenu;
