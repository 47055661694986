import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const TournamentListPage = () => {
    const [tournaments, setTournaments] = useState([]);
    const [newTournament, setNewTournament] = useState({
        name: '',
        summary: '',
        regulations: '',
        avatar: '',
        location: '',
        organizingUnit: '',
        tournamentPoints: '',
        maxIndividualPoints: '',
        expectedStartDate: '',
        expectedEndDate: '',
        registrationDeadline: '',
        minParticipants: '',
        maxParticipants: '',
        notes: '',
        status: ''
    });
    const [editingTournament, setEditingTournament] = useState(null);
    const [showModal, setShowModal] = useState(false);  // Modal state
    const [deleteId, setDeleteId] = useState(null);  // ID để xác nhận xóa

    useEffect(() => {
        fetchTournaments();
    }, []);

    const fetchTournaments = async () => {
        const response = await axios.get('/tournaments');
        setTournaments(response.data);
    };

    const handleSaveTournament = async () => {
        if (editingTournament) {
            await axios.put(`/tournaments/${editingTournament._id}`, editingTournament);
        } else {
            await axios.post('/tournaments', newTournament);
            setNewTournament({
                name: '',
                summary: '',
                regulations: '',
                avatar: '',
                location: '',
                organizingUnit: '',
                tournamentPoints: '',
                maxIndividualPoints: '',
                expectedStartDate: '',
                expectedEndDate: '',
                registrationDeadline: '',
                minParticipants: '',
                maxParticipants: '',
                notes: '',
                status: ''
            });
        }
        setShowModal(false);
        setEditingTournament(null);
        fetchTournaments();
    };

    const handleDeleteTournament = async (id) => {
        await axios.delete(`/tournaments/${id}`);
        setDeleteId(null);
        fetchTournaments();
    };

    const openModal = (tournament = null) => {
        setEditingTournament(tournament);
        setNewTournament(tournament || {
            name: '',
            summary: '',
            regulations: '',
            avatar: '',
            location: '',
            organizingUnit: '',
            tournamentPoints: '',
            maxIndividualPoints: '',
            expectedStartDate: '',
            expectedEndDate: '',
            registrationDeadline: '',
            minParticipants: '',
            maxParticipants: '',
            notes: '',
            status: ''
        });
        setShowModal(true);
    };

    const closeModal = () => {
        setEditingTournament(null);
        setShowModal(false);
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);

        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingTournament) {
                setEditingTournament({ ...editingTournament, avatar: fileUrl });
            } else {
                setNewTournament({ ...newTournament, avatar: fileUrl });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    return (
        <Container>
            <h2 className="my-4">Tournament Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Tournament</Button>

            <h3>Existing Tournaments</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Avantar</th>
                        <th>Organizing Unit</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tournaments.map((tournament) => (
                        <tr key={tournament._id}>
                            <td>{tournament.name}</td>
                            <td>{tournament.location}</td>
                            <td>
                                <img src={`${process.env.REACT_APP_API_BASE_URL}/${tournament.avatar}`} alt="Avatar" width="100" />
                            </td>
                            <td>{tournament.organizingUnit}</td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(tournament)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => confirmDelete(tournament._id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal cho thêm/sửa tournament */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingTournament ? 'Edit Tournament' : 'Create New Tournament'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Tournament Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingTournament ? editingTournament.name : newTournament.name}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, name: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, name: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Summary</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingTournament ? editingTournament.summary : newTournament.summary}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, summary: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, summary: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Regulations</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingTournament ? editingTournament.regulations : newTournament.regulations}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, regulations: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, regulations: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Location</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingTournament ? editingTournament.location : newTournament.location}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, location: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, location: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Organizing Unit</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingTournament ? editingTournament.organizingUnit : newTournament.organizingUnit}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, organizingUnit: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, organizingUnit: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Tournament Points</Form.Label>
                            <Form.Control
                                type="number"
                                value={editingTournament ? editingTournament.tournamentPoints : newTournament.tournamentPoints}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, tournamentPoints: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, tournamentPoints: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Max Individual Points</Form.Label>
                            <Form.Control
                                type="number"
                                value={editingTournament ? editingTournament.maxIndividualPoints : newTournament.maxIndividualPoints}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, maxIndividualPoints: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, maxIndividualPoints: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Expected Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={editingTournament ? new Date(editingTournament.expectedStartDate).toISOString().split('T')[0] : newTournament.expectedStartDate}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, expectedStartDate: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, expectedStartDate: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Expected End Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={editingTournament ? new Date(editingTournament.expectedEndDate).toISOString().split('T')[0] : newTournament.expectedEndDate}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, expectedEndDate: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, expectedEndDate: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Registration Deadline</Form.Label>
                            <Form.Control
                                type="date"
                                value={editingTournament ? new Date(editingTournament.registrationDeadline).toISOString().split('T')[0] : newTournament.registrationDeadline}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, registrationDeadline: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, registrationDeadline: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Min Participants</Form.Label>
                            <Form.Control
                                type="number"
                                value={editingTournament ? editingTournament.minParticipants : newTournament.minParticipants}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, minParticipants: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, minParticipants: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Max Participants</Form.Label>
                            <Form.Control
                                type="number"
                                value={editingTournament ? editingTournament.maxParticipants : newTournament.maxParticipants}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, maxParticipants: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, maxParticipants: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingTournament ? editingTournament.notes : newTournament.notes}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, notes: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, notes: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={editingTournament ? editingTournament.status : newTournament.status}
                                onChange={(e) => {
                                    if (editingTournament) {
                                        setEditingTournament({ ...editingTournament, status: e.target.value });
                                    } else {
                                        setNewTournament({ ...newTournament, status: e.target.value });
                                    }
                                }}
                            >
                                <option value="">Select Status</option>
                                <option value="Đăng ký">Đăng ký</option>
                                <option value="Đóng Đăng Ký">Đóng Đăng Ký</option>
                                <option value="Đang Diễn Ra">Đang Diễn Ra</option>
                                <option value="Kết Thúc">Kết Thúc</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveTournament}>
                        {editingTournament ? 'Save Changes' : 'Create Tournament'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this tournament?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteTournament(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default TournamentListPage;
