import React from 'react';
import { Link } from 'react-router-dom';

const CommonMenu = () => {

  return (
    <nav style={{ width: '200px', backgroundColor: '#f8f9fa', padding: '10px' }}>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>
          <Link to="../manage/club-members">Quản lý thành viên</Link>
        </li>
        <li>
          <Link to="../manage/club-practices">Quản lý buổi tập luyện</Link>
        </li>
        <li>
          <Link to="../manage/playgrouds">Quản lý sân cho thuê</Link>
        </li>
        <li>
          <Link to="../manage/slots">Quản lý giờ cho thuê</Link>
        </li>
      </ul>
    </nav>
  );
}
export default CommonMenu;
