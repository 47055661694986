import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal } from 'react-bootstrap';

const SportListPage = () => {
    const [sports, setSports] = useState([]);
    const [newSport, setNewSport] = useState({ name: '', description: '', avatar: '' });
    const [editingSport, setEditingSport] = useState(null);
    const [showModal, setShowModal] = useState(false);  // Modal state
    const [deleteId, setDeleteId] = useState(null);  // ID để xác nhận xóa

    // Lấy tất cả các môn thể thao
    useEffect(() => {
        fetchSports();
    }, []);

    const fetchSports = async () => {
        const response = await axios.get('/sports');
        setSports(response.data);
    };

    // Thêm hoặc cập nhật môn thể thao
    const handleSaveSport = async () => {
        if (editingSport) {
            await axios.put(`/sports/${editingSport._id}`, editingSport);
        } else {
            await axios.post('/sports', newSport);
            setNewSport({ name: '', description: '', avatar: '' });
        }
        setShowModal(false);
        setEditingSport(null);
        fetchSports();
    };

    // Xóa môn thể thao
    const handleDeleteSport = async (id) => {
        await axios.delete(`/sports/${id}`);
        setDeleteId(null);
        fetchSports();
    };

    // Mở Modal cho thêm hoặc sửa
    const openModal = (sport = null) => {
        setEditingSport(sport);
        setNewSport(sport || { name: '', description: '', avatar: '' });
        setShowModal(true);
    };

    // Đóng Modal
    const closeModal = () => {
        setEditingSport(null);
        setShowModal(false);
    };

    // Upload ảnh
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('avatar', file);  // 'avatar' là tên của trường file trong backend
    
        try {
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            const fileUrl = response.data.url;
            if (editingSport) {
                setEditingSport({ ...editingSport, avatar: fileUrl });
            } else {
                setNewSport({ ...newSport, avatar: fileUrl });
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    // Xác nhận xóa
    const confirmDelete = (id) => {
        setDeleteId(id);
    };

    return (
        <Container>
            <h2 className="my-4">Sports Management</h2>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Create New Sport</Button>

            <h3>Existing Sports</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Avatar</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sports.map((sport) => (
                        <tr key={sport._id}>
                            <td>{sport.name}</td>
                            <td>{sport.description}</td>
                            <td>
                                <img src={sport.avatar} alt="Avatar" width="100" />
                            </td>
                            <td>
                                <Button
                                    variant="warning"
                                    className="me-2"
                                    onClick={() => openModal(sport)}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => confirmDelete(sport._id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Modal cho thêm/sửa môn thể thao */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingSport ? 'Edit Sport' : 'Create New Sport'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Sport Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingSport ? editingSport.name : newSport.name}
                                onChange={(e) => {
                                    if (editingSport) {
                                        setEditingSport({ ...editingSport, name: e.target.value });
                                    } else {
                                        setNewSport({ ...newSport, name: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingSport ? editingSport.description : newSport.description}
                                onChange={(e) => {
                                    if (editingSport) {
                                        setEditingSport({ ...editingSport, description: e.target.value });
                                    } else {
                                        setNewSport({ ...newSport, description: e.target.value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Avatar</Form.Label>
                            <Form.Control type="file" onChange={handleFileChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveSport}>
                        {editingSport ? 'Save Changes' : 'Create Sport'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal xác nhận xóa */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this sport?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteSport(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default SportListPage;
