import React from 'react';
import { Routes, Route } from 'react-router-dom';

import ClubMemberManagement from '../admin/Club/ClubMemberManagement';
import ClubPracticeSessionManagement from '../admin/Club/ClubPracticeSessionManagement';
import PlaygroundManagementListPage from '../admin/Stadium/PlaygroundManagementListPage';
import SlotManagement from '../admin/Stadium/SlotManagement';

function CommunityLayout() {
    return (
        <div className="main-content">
            <Routes>
                <Route path="/manage/club-members" element={<ClubMemberManagement />} />
                <Route path="/manage/club-practices" element={<ClubPracticeSessionManagement />} />
                <Route path="/manage/playgrouds" element={<PlaygroundManagementListPage />} />
                <Route path="/manage/slots" element={<SlotManagement />} />
                {/* Thêm các routes khác ở đây */}
            </Routes>
        </div>
    );
}

export default CommunityLayout;
