import React, { useEffect, useState } from 'react';
import axios from './../api/api';
import Slider from 'react-slick'; // Import Slider từ react-slick

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RefereesHomepage = ({ refereeConfigValue }) => {
    const [referees, setReferees] = useState([]);

    useEffect(() => {
        console.log('Received refereeConfigValue:', refereeConfigValue);

        const fetchReferees = async () => {
            try {
                const refereeIdsArray = refereeConfigValue.slice(1, -1).split(',');
                const sanitizedIds = refereeIdsArray.map(id => id.trim());

                const response = await axios.get('/referees/referees-by-ids', {
                    params: { ids: sanitizedIds }
                });

                setReferees(response.data);
            } catch (error) {
                console.error('Error fetching referees:', error);
            }
        };

        if (refereeConfigValue) {
            fetchReferees();
        }
    }, [refereeConfigValue]);

    // Cấu hình cho slider
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2, // Hiển thị 2 trọng tài trên mỗi dòng
        slidesToScroll: 1, // Trượt một lần 1 nhóm
        autoplay: true,
        autoplaySpeed: 6000, // Tự động chuyển sau 6 giây
    };

    return (
        <div>
            <div style={{
                backgroundColor: '#007BFF', // Màu nền xanh
                color: 'white',              // Chữ trắng
                textAlign: 'center',         // Canh giữa
                padding: '10px',             // Khoảng cách trên dưới
                fontSize: '1.5em',           // Kích thước chữ nhỏ hơn
            }}>
                Trọng tài tiêu biểu
            </div>
            {referees.length > 0 && (
                <Slider {...settings}>
                    {referees.map((referee, index) => (
                        <div key={index} style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
                            {/* Cột hình ảnh */}
                            <div style={{ flex: '0 0 150px', marginRight: '20px' }}>
                                <img 
                                    src={`${process.env.REACT_APP_API_BASE_URL}/${referee.avatar}`}
                                    alt={referee.nick} 
                                    style={{
                                        width: '150px',
                                        height: '150px',
                                        borderRadius: '50%', // Bo tròn hình ảnh
                                        objectFit: 'cover',
                                        border: '3px solid #ddd' // Tùy chọn: viền nhẹ
                                    }} 
                                />
                            </div>
                            {/* Cột nội dung */}
                            <div style={{ textAlign: 'left', flex: '1' }}>
                                <h3>{referee.fullName}</h3>
                                <p><strong>Phone:</strong> {referee.phoneNumber}</p>
                                <p>{referee.description}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            )}
        </div>
    );
};

export default RefereesHomepage;
