import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import AdminMenu from '../components/admin/AdminMenu';  // Import Dashboard
import AdminHeader from '../components/admin/AdminHeader';
import AdminLayout from '../components/admin/AdminLayout';


const ProtecedAdminRoutes = () => {
    const { auth, loading } = useAuth();

    if (loading) {
        return <div>Loading...</div>;
    }

    return auth ? (
        
        <Routes>            
            <Route path='*' element = {
                <div>
                    <AdminHeader />
                    <div style={{ display: 'flex' }}>
                        <AdminMenu />
                        <AdminLayout />
                    </div>
                </div>
            }
            />            
            
        </Routes>
    ) : (
        <Navigate to="/login" />
    );
};

export default ProtecedAdminRoutes;
