import React, { useState, useEffect } from 'react';
import axios from './../../../api/api';
import { Button, Form, Table, Container, Modal, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const VideoManagement = () => {
    const [videos, setVideos] = useState([]);
    const [sports, setSports] = useState([]); // State to hold sport data
    const [newVideo, setNewVideo] = useState({
        sportId: '',
        name: '',
        youtubeLink: '',
        description: '',
        videoDate: ''
    });
    const [editingVideo, setEditingVideo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [search, setSearch] = useState('');  // Search box
    const [currentPage, setCurrentPage] = useState(1);  // Current page
    const [totalPages, setTotalPages] = useState(1);  // Total pages
    const navigate = useNavigate();

    useEffect(() => {
        fetchVideos();
    }, [currentPage, search]);

    // Fetch videos
    const fetchVideos = async () => {
        const response = await axios.get(`/videos?page=${currentPage}&limit=5&search=${search}`);
        setVideos(response.data.videos);
        setTotalPages(response.data.totalPages);
    };

    // Fetch sports for the dropdown
    useEffect(() => {
        const fetchSports = async () => {
            const response = await axios.get('/sports');
            setSports(response.data);
        };
        fetchSports();
    }, []);

    // Add or update video
    const handleSaveVideo = async () => {
        if (editingVideo) {
            await axios.put(`/videos/${editingVideo._id}`, editingVideo);
        } else {
            await axios.post('/videos', newVideo);
            setNewVideo({
                sportId: '',
                name: '',
                youtubeLink: '',
                description: '',
                videoDate: ''
            });
        }
        setShowModal(false);
        setEditingVideo(null);
        fetchVideos();
    };

    // Delete video
    const handleDeleteVideo = async (id) => {
        await axios.delete(`/videos/${id}`);
        setDeleteId(null);
        fetchVideos();
    };

    // Open modal for adding or editing video
    const openModal = (video = null) => {
        setEditingVideo(video);
        setNewVideo(video || {
            sportId: '',
            name: '',
            youtubeLink: '',
            description: '',
            videoDate: ''
        });
        setShowModal(true);
    };

    // Close modal
    const closeModal = () => {
        setEditingVideo(null);
        setShowModal(false);
    };

    // Search handler
    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setCurrentPage(1);  // Reset to page 1 when searching
    };

    // Pagination handler
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <Container>
            <h2 className="my-4">Video Management</h2>

            {/* Search Box */}
            <Form.Group className="mb-3">
                <Form.Control
                    type="text"
                    placeholder="Search videos by name..."
                    value={search}
                    onChange={handleSearchChange}
                />
            </Form.Group>

            <Button variant="primary" className="mb-4" onClick={() => openModal()}>Add New Video</Button>

            <h3>Existing Videos</h3>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Sport</th>
                        <th>Video Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {videos.map((video) => (
                        <tr key={video._id}>
                            <td>{video.name}</td>
                            <td>{video.sportId?.name}</td>
                            <td>{new Date(video.videoDate).toLocaleDateString()}</td>
                            <td>
                                <Button variant="warning" className="me-2" onClick={() => openModal(video)}>Edit</Button>
                                <Button variant="danger" onClick={() => setDeleteId(video._id)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Pagination */}
            <Pagination>
                {[...Array(totalPages).keys()].map(page => (
                    <Pagination.Item
                        key={page + 1}
                        active={page + 1 === currentPage}
                        onClick={() => handlePageChange(page + 1)}
                    >
                        {page + 1}
                    </Pagination.Item>
                ))}
            </Pagination>

            {/* Modal for adding/editing video */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingVideo ? 'Edit Video' : 'Add New Video'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Sport</Form.Label>
                            <Form.Select
                                name="sportId"
                                value={editingVideo ? editingVideo.sportId : newVideo.sportId}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingVideo) {
                                        setEditingVideo({ ...editingVideo, sportId: value });
                                    } else {
                                        setNewVideo({ ...newVideo, sportId: value });
                                    }
                                }}
                            >
                                <option value="">Select Sport</option>
                                {sports.map((sport) => (
                                    <option key={sport._id} value={sport._id}>
                                        {sport.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingVideo ? editingVideo.name : newVideo.name}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingVideo) {
                                        setEditingVideo({ ...editingVideo, name: value });
                                    } else {
                                        setNewVideo({ ...newVideo, name: value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Youtube Link</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingVideo ? editingVideo.youtubeLink : newVideo.youtubeLink}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingVideo) {
                                        setEditingVideo({ ...editingVideo, youtubeLink: value });
                                    } else {
                                        setNewVideo({ ...newVideo, youtubeLink: value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={editingVideo ? editingVideo.description : newVideo.description}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingVideo) {
                                        setEditingVideo({ ...editingVideo, description: value });
                                    } else {
                                        setNewVideo({ ...newVideo, description: value });
                                    }
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Video Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={editingVideo ? editingVideo.videoDate : newVideo.videoDate}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (editingVideo) {
                                        setEditingVideo({ ...editingVideo, videoDate: value });
                                    } else {
                                        setNewVideo({ ...newVideo, videoDate: value });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleSaveVideo}>
                        {editingVideo ? 'Save Changes' : 'Add Video'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for confirming deletion */}
            <Modal show={!!deleteId} onHide={() => setDeleteId(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this video?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button variant="danger" onClick={() => handleDeleteVideo(deleteId)}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default VideoManagement;
