import React, { useState, useEffect } from 'react';
import './ClubList.css';
import axios from './../api/api';

function ClubList() {
    const [clubs, setClubs] = useState([]); // Dữ liệu câu lạc bộ
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Tổng số trang
    const [searchTerm, setSearchTerm] = useState(''); // Từ khóa tìm kiếm
    const [searchQuery, setSearchQuery] = useState(''); // Từ khóa tìm kiếm khi nhấn nút tìm
    const clubsPerPage = 10;

    // Lấy danh sách câu lạc bộ từ API với phân trang và tìm kiếm
    useEffect(() => {
        const fetchClubs = async () => {
            try {
                const response = await axios.get('/clubs/clubs-paginated', {
                    params: {
                        page: currentPage,
                        limit: clubsPerPage,
                        search: searchQuery // Truyền từ khóa tìm kiếm lên API
                    }
                });
                setClubs(response.data.clubs); // Cập nhật danh sách câu lạc bộ
                setTotalPages(response.data.totalPages); // Cập nhật tổng số trang
            } catch (error) {
                console.error('Error fetching clubs:', error);
            }
        };

        fetchClubs();
    }, [currentPage, searchQuery]); // Gọi lại khi trang hoặc từ khóa tìm kiếm thay đổi

    // Xử lý tìm kiếm câu lạc bộ
    const handleSearch = () => {
        setSearchQuery(searchTerm); // Chỉ khi nhấn nút mới cập nhật từ khóa tìm kiếm
        setCurrentPage(1); // Đặt lại về trang đầu tiên khi tìm kiếm
    };

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="club-list container">
            <div className="search-bar" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <input 
                    type="text" 
                    value={searchTerm} 
                    onChange={(e) => setSearchTerm(e.target.value)} 
                    placeholder="Search for clubs by name or description..." 
                    style={{ padding: '10px', width: '300px', marginRight: '10px' }}
                />
                <button onClick={handleSearch} style={{ padding: '10px 20px' }}>Search</button>
            </div>

            {clubs.length > 0 ? (
                clubs.map(club => (
                    <div key={club._id} className="club-item">
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/${club.avatar}`} alt={club.name} />
                        <div className="club-info">
                            <h3>{club.name}</h3>
                            <p><strong>Address:</strong> {club.location}</p>
                            <p><strong>Representative:</strong> {club.president}</p>
                            <p><strong>Description:</strong> {club.introduction}</p>
                        </div>
                    </div>
                ))
            ) : (
                <p>No clubs found.</p>
            )}

            <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>
        </div>
    );
}

export default ClubList;
