import React, { useState, useEffect } from 'react';
import { Form, Button, Table, Alert, Modal } from 'react-bootstrap';
import api from '../../../api/api';  // API helper

const ClubMemberManagement = () => {
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState('');
  const [members, setMembers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);  // For the Add/Edit modal
  const [editingMember, setEditingMember] = useState(null);  // Track member being edited

  useEffect(() => {
    //console.log("fetchClubs");
    // Fetch the user's clubs
    const fetchClubs = async () => {
      try {
        console.log("fetchClubs");
        const response = await api.get('/clubs/club-of-users');  // API to get clubs based on userId
        const userClubs = response.data.clubs;  // Đảm bảo lấy đúng 'clubs' từ response

        if (userClubs.length === 0) {
          setError("Bạn không được quản trị CLB nào, vui lòng liên hệ chủ nhiệm CLB hoặc đăng ký quản lý CLB.");
        } else {
          setClubs(userClubs);
          setSelectedClub(userClubs[0].clubId._id);  // Chọn câu lạc bộ đầu tiên (dùng clubId._id)
          fetchMembers(userClubs[0].clubId._id);  // Load members cho câu lạc bộ đầu tiên
        }
      } catch (err) {
        console.error("Error fetching clubs:", err);
        setError("Lỗi khi tải dữ liệu CLB.");
      } finally {
        setLoading(false);
      }
    };

    fetchClubs();
  }, []);

  const fetchMembers = async (clubId) => {
    try {
      const response = await api.get(`/club-members/${clubId}/members`);
      setMembers(response.data);
    } catch (err) {
      console.error("Error fetching members:", err);
      setError("Lỗi khi tải dữ liệu thành viên.");
    }
  };

  const handleClubChange = (event) => {
    setSelectedClub(event.target.value);
    fetchMembers(event.target.value);
  };

  const handleAddMember = () => {
    setEditingMember(null);  // Clear the form for adding a new member
    setShowModal(true);
  };

  const handleEditMember = (member) => {
    setEditingMember(member);
    setShowModal(true);
  };

  const handleDeleteMember = async (memberId) => {
    try {
      await api.delete(`/club-members/${memberId}`);
      fetchMembers(selectedClub);  // Refresh the member list
    } catch (err) {
      console.error("Error deleting member:", err);
      setError("Lỗi khi xóa thành viên.");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const payload = {
        clubId: selectedClub,
        nick: event.target.nick.value,
        fullName: event.target.fullName.value,
        dateOfBirth: event.target.dateOfBirth.value || null,
        email: event.target.email.value || null,
        phone: event.target.phone.value || null,
        status: event.target.status.value
      };

      if (editingMember) {
        // Update member
        await api.put(`/club-members/${editingMember._id}`, payload);
      } else {
        // Add new member
        await api.post(`/club-members/${selectedClub}/members`, payload);
      }

      fetchMembers(selectedClub);  // Refresh the member list
      setShowModal(false);  // Close the modal
    } catch (err) {
      console.error("Error saving member:", err);
      setError("Lỗi khi lưu thành viên.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="club-member-management">
      <h2>Quản lý thành viên CLB</h2>

      {error && <Alert variant="danger">{error}</Alert>}

      {clubs.length > 0 && (
        <Form.Group controlId="clubSelect">
          <Form.Label>Chọn Câu Lạc Bộ</Form.Label>
          <Form.Control as="select" value={selectedClub} onChange={handleClubChange}>
            {clubs.map(club => (
              <option key={club.clubId._id} value={club.clubId._id}>
                {club.clubId.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      <Button variant="primary" className="my-3" onClick={handleAddMember}>
        Thêm thành viên mới
      </Button>

      {members.length > 0 ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nick</th>
              <th>Họ và Tên</th>
              <th>Ngày sinh</th>
              <th>Email</th>
              <th>Số điện thoại</th>
              <th>Trạng thái</th>
              <th>Hành động</th>
            </tr>
          </thead>
          <tbody>
            {members.map(member => (
              <tr key={member._id}>
                <td>{member.nick}</td>
                <td>{member.fullName}</td>
                <td>{member.dateOfBirth ? new Date(member.dateOfBirth).toLocaleDateString() : 'N/A'}</td>
                <td>{member.email || 'N/A'}</td>
                <td>{member.phone || 'N/A'}</td>
                <td>{member.status}</td>
                <td>
                  <Button variant="warning" onClick={() => handleEditMember(member)}>Sửa</Button>
                  <Button variant="danger" onClick={() => handleDeleteMember(member._id)} className="ml-2">Xóa</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <p>Không có thành viên nào trong CLB này.</p>
      )}

      {/* Modal for adding/editing a member */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editingMember ? 'Sửa Thành Viên' : 'Thêm Thành Viên Mới'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="nick">
              <Form.Label>Nick</Form.Label>
              <Form.Control type="text" defaultValue={editingMember?.nick || ''} required />
            </Form.Group>
            <Form.Group controlId="fullName">
              <Form.Label>Họ và Tên</Form.Label>
              <Form.Control type="text" defaultValue={editingMember?.fullName || ''} required />
            </Form.Group>
            <Form.Group controlId="dateOfBirth">
              <Form.Label>Ngày sinh</Form.Label>
              <Form.Control type="date" defaultValue={editingMember?.dateOfBirth ? new Date(editingMember.dateOfBirth).toISOString().split('T')[0] : ''} />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" defaultValue={editingMember?.email || ''} />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label>Số điện thoại</Form.Label>
              <Form.Control type="text" defaultValue={editingMember?.phone || ''} />
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Trạng thái</Form.Label>
              <Form.Control as="select" defaultValue={editingMember?.status || 'pending'}>
                <option value="draft">Nháp</option>
                <option value="pending">Chờ duyệt</option>
                <option value="approved">Đã duyệt</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" type="submit">
              {editingMember ? 'Cập nhật' : 'Thêm mới'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ClubMemberManagement;
